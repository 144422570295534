<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="red" dark v-bind="attrs" v-on="on">
        <v-icon small left color="white"> mdi-alert </v-icon>
        Vertrag zum {{ formatDate(date) }} kündigen
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="red" elevation="0" class="white--text">
        <v-toolbar-title>Vertrag kündigen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation class="my-4">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="reason"
                :rules="reasonRules"
                placeholder="Bitte schildere uns, warum der Kunde gekündigt hat."
                label="Grund"
                required
              ></v-textarea>
            </v-col>

            <v-col cols="12" v-if="data.paused.total">
              <v-alert border="top" color="red" dark>
                ACHTUNG:
                {{
                  data.paused.total > 1
                    ? `Das Addon wurde insgesamt für ${data.paused.total} Monate pausiert!`
                    : `Das Addon wurde insgesamt für ${data.paused.total} Monat pausiert!`
                }}
              </v-alert>
            </v-col>

            <v-col cols="12" md="6" v-if="extraordinary">
              <v-text-field
                v-model="tDate"
                type="date"
                :rules="dateRules"
                label="Kündigungsdatum (Ende der Laufzeit)"
                :disabled="!extraordinary"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-else>
              Kündigung zum {{ formatDate(date) }}
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="extraordinary"
                label="Außerordentliche Kündigung"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!valid"
          color="red"
          class="mr-4 white--text"
          @click="terminateContract"
        >
          Zum {{ formatDate(tDate) }} Kündigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "TerminateContract",
  components: {},
  props: ["companyId", "date", "addonName", "data"],

  data() {
    return {
      dialog: false,
      fetchingData: false,
      reason: "",
      extraordinary: false,
      valid: false,
      tDate: this.date,
      reasonRules: [
        (v) => !!v || "Bitte gebe eine Begründung ein.",
        (v) => (v && v.length >= 30) || "Mindestens 30 Zechen sind notwendig.",
      ],
      dateRules: [(v) => !!v || "Ein Datum ist Pflicht"],
    };
  },

  methods: {
    async terminateContract() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const userChoice = await this.$dialog.warning({
          text: `Soll dieser Vertrag wirklich zum ${this.tDate} gekündigt werden?`,
          title: "Achtung!",
          waitForResult: true,
        });

        if (userChoice) {
          // do it
          const params = new URLSearchParams();

          if (this.extraordinary) {
            // Datum wird nur bei Sonderkündigung geschickt!
            params.append("date", this.tDate);
          }
          params.append("reason", this.reason);

          this.fetchingData = true;
          let response = await this.patchRequest(
            `addons/${this.addonName}/config/${this.companyId}/terminate`,
            params
          );
          this.fetchingData = false;

          if (response.status === 200) {
            console.log(response);
            this.$emit("terminated", response.data);
          }
        }
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
