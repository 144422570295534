<template>
  <v-card>
    <v-card-title>Pausenzeiten</v-card-title>
    <v-card-text v-if="!fetchingData">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-row justify="center">
            <v-col cols="12">
              <v-date-picker
                v-model="months"
                type="month"
                locale="de-de"
                :selected-items-text="selectedFeedback()"
                :show-current="false"
                :range="true"
                :allowed-dates="allowedMonths"
                :events="breakMonths()"
                event-color="red"
                :disabled="pending"
                multiple
              ></v-date-picker>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                :loading="pending"
                :disabled="!rangeSelected"
                @click="addBreak()"
              >
                Pause festlegen
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Von</th>
                  <th class="text-left">Bis</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in data.breaks" :key="index">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";

export default {
  name: "AddonBreaks",
  components: {},
  props: ["companyId", "addonName", "configId"],

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      pending: false,
      data: [],
      months: [],
      rangeSelected: false,
      monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    calculateMonthsInRange(range) {
      const startDate = new Date(range[0]);
      const endDate = new Date(range[1]);
      const months = [];

      // Set the start date to the beginning of the month
      startDate.setDate(1);
      // Set the end date to the end of the month
      endDate.setDate(28);

      // Loop through each month until the end date
      while (startDate <= endDate) {
        const year = startDate.getFullYear();
        const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
        const formattedMonth = `${year}-${month}`;
        months.push(formattedMonth);
        // Move to the next month
        startDate.setMonth(startDate.getMonth() + 1);
      }

      return months;
    },

    allowedMonths(val) {
      let allDates = [];
      if (this.data.breaks) {
        this.data.breaks.forEach((range) => {
          allDates.push(this.calculateMonthsInRange(range));
        });
        // merge all arrays in allDates into one array
        allDates = [].concat.apply([], allDates);
        return !allDates.includes(val);
      }
      return allDates;
    },

    breakMonths() {
      let allDates = [];

      if (this.data.breaks) {
        this.data.breaks.forEach((range) => {
          allDates.push(this.calculateMonthsInRange(range));
        });
        // merge all arrays in allDates into one array
        allDates = [].concat.apply([], allDates);
      }
      return allDates;
    },

    selectedFeedback() {
      // calculate the number of months between the two dates

      const date1 = new Date(this.months[0]);
      const date2 = new Date(this.months[1]);

      if (!isNaN(date1) && !isNaN(date2)) {
        this.rangeSelected = true;

        const month1 = this.monthNames[date1.getMonth()];
        const year1 = date1.getFullYear();
        const month2 = this.monthNames[date2.getMonth()];
        const year2 = date2.getFullYear();

        if (year1 === year2) {
          return `${month1} - ${month2}`;
        }

        return `${month1} ${year1} - ${month2} ${year2}`;
      }
      this.rangeSelected = false;
      return "";
    },

    async addBreak() {
      const userChoice = await this.$dialog.confirm({
        text: `Bist du dir sicher, dass das Addon zwischen ${this.selectedFeedback()} pausiert werden soll?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        const formData = new FormData();
        formData.append("startDate", this.months[0]);
        formData.append("endDate", this.months[1]);
        formData.append("configs_id", this.configId);

        this.pending = true;
        let response = await this.postRequest(
          `companies/${this.companyId}/addons/${this.addonName}/breaks`,
          null,
          formData
        );
        if (response.status === 200) {
          this.months = [];
          // emit update
          this.$emit("newBreak", true);
          this.getData();
        }

        this.pending = false;
      } else {
        this.months = [];
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyId}/addons/${this.addonName}/breaks`
      );
      if (response.status === 200) {
        this.data = response.data.data;
      }

      this.fetchingData = false;
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
