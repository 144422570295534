<template>
  <v-container>
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <v-card class="mx-auto">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Addons</v-toolbar-title>
          </v-toolbar>
          <v-list subheader two-line>
            <v-list-item v-for="(item, index) in addonsList" :key="item.id">
              <v-list-item-avatar>
                <v-icon
                  v-if="companyAddons.indexOf(item.id) >= 0"
                  class="green"
                  dark
                  >mdi-rocket-launch</v-icon
                >
                <v-icon v-else class="grey lighten-2" dark
                  >mdi-power-sleep</v-icon
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <span
                  v-if="companyAddons.indexOf(item.id) >= 0"
                  class="black--text"
                  >{{ item.name }}</span
                >
                <span v-else class="grey--text">{{ item.name }}</span>
              </v-list-item-content>

              <v-spacer></v-spacer>

              <v-btn
                v-if="companyAddons.indexOf(item.id) >= 0"
                class="mx-1"
                fab
                x-small
                dark
                color="green"
                elevation="0"
                @click="editAddon(item.id, index)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                v-else
                class="mx-1"
                fab
                x-small
                dark
                color="orange"
                elevation="0"
                @click="activateAddon(item.id, index)"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogEditAddon"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>{{ addonName }} bearbeiten</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogEditAddon = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-container fluid>
            <EditAddonAnnualMarketing
              v-if="addonId === '1'"
              @closeDialog="dialogEditAddon = false"
              :companyId="$route.params.id"
            ></EditAddonAnnualMarketing>

            <EditAddonMember
              v-else-if="addonId === '6'"
              @closeDialog="dialogEditAddon = false"
              @terminated="addonDeleted()"
              :companyId="$route.params.id"
            ></EditAddonMember>

            <EditAddonShop
              v-else-if="addonId === '7'"
              @closeDialog="dialogEditAddon = false"
              @terminated="addonDeleted()"
              :companyId="$route.params.id"
            ></EditAddonShop>

            <EditAddonEventCalendar
              v-else-if="addonId === '9'"
              @closeDialog="dialogEditAddon = false"
              @terminated="addonDeleted()"
              :companyId="$route.params.id"
            ></EditAddonEventCalendar>

            <EditAddonInfoMails
              v-else-if="addonId === '8'"
              @closeDialog="dialogEditAddon = false"
              @terminated="addonDeleted()"
              :companyId="$route.params.id"
            ></EditAddonInfoMails>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogActivateAddon"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar dark color="orange" elevation="0">
          <v-toolbar-title>{{ addonName }} aktivieren</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogActivateAddon = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <v-container fluid>
            <ActivateAddonAnnualMarketing
              v-if="addonId === '1'"
              @addonActivated="addonActivated()"
            ></ActivateAddonAnnualMarketing>

            <ActivateAddonMember
              v-else-if="addonId === '6'"
              @addonActivated="addonActivated()"
            ></ActivateAddonMember>

            <ActivateAddonShop
              v-else-if="addonId === '7'"
              @addonActivated="addonActivated()"
            ></ActivateAddonShop>

            <ActivateAddonInfoMails
              v-else-if="addonId === '8'"
              @addonActivated="addonActivated()"
            ></ActivateAddonInfoMails>

            <ActivateAddonEventCalendar
              v-else-if="addonId === '9'"
              @addonActivated="addonActivated()"
            ></ActivateAddonEventCalendar>

            <ActivatePlaceholder
              v-else
              @closeDialog="dialogActivateAddon = false"
            ></ActivatePlaceholder>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import EditAddonAnnualMarketing from "@/components/companies/addons/annual_marketing/Edit.vue";
import ActivateAddonAnnualMarketing from "@/components/companies/addons/annual_marketing/Activate.vue";

import ActivateAddonMember from "@/components/companies/addons/member/Activate.vue";
import EditAddonMember from "@/components/companies/addons/member/Edit.vue";

import ActivateAddonShop from "@/components/companies/addons/shop/Activate.vue";
import EditAddonShop from "@/components/companies/addons/shop/Edit.vue";

import ActivateAddonInfoMails from "@/components/companies/addons/info_mails/Activate.vue";
import EditAddonInfoMails from "@/components/companies/addons/info_mails/Edit.vue";

import ActivateAddonEventCalendar from "@/components/companies/addons/event_calendar/Activate.vue";
import EditAddonEventCalendar from "@/components/companies/addons/event_calendar/Edit.vue";

import ActivatePlaceholder from "@/components/companies/addons/placeholder/Activate.vue";

export default {
  name: "Addons",

  components: {
    PageHeader,
    Subnavigation,
    EditAddonAnnualMarketing,
    ActivateAddonAnnualMarketing,
    EditAddonMember,
    ActivateAddonMember,
    ActivateAddonShop,
    EditAddonShop,
    ActivatePlaceholder,
    ActivateAddonInfoMails,
    EditAddonInfoMails,
    ActivateAddonEventCalendar,
    EditAddonEventCalendar,
  },

  data() {
    return {
      dialogEditAddon: false,
      dialogActivateAddon: false,
      addonId: null,
      addonName: null,
      pageTitle: "Addons",
      pageDescription:
        "Nicht standardmäßige Addons wie Elias, Holoscan, Shop etc.",
      title: "",
      preloader: false,
      data: [],
      cardTeaser: "",
      addonsList: [],
      companyAddons: [],
      companyTerminatedAddons: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    addonDeleted() {
      this.dialogEditAddon = false;

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = "Das Addon wurde entfernt";
      this.$root.snackbarGlobal.errorMessage = "";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;

      this.getData();
    },

    addonActivated() {
      this.dialogActivateAddon = false;

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Das Addon wurde erfolgreich aktiviert";
      this.$root.snackbarGlobal.errorMessage = "";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;

      this.getData();
    },

    activateAddon(id, index) {
      this.addonId = id;
      this.addonName = this.addonsList[index].name;
      this.dialogActivateAddon = true;
    },

    editAddon(id, index) {
      this.addonId = id;
      this.addonName = this.addonsList[index].name;
      this.dialogEditAddon = true;
    },

    async getData() {
      this.preloader = true;
      /* company name */
      let companyData = await this.getRequest(
        "companies/" + this.$route.params.id
      ); // await data from mixin
      /* get Addons */
      let response = await this.getRequest("addons"); // await data from mixin
      let companyAddons = await this.getRequest(
        "companies/" + this.$route.params.id + "/addons"
      ); // await data from mixin

      // HACK: loop threw companyAddons.data.data and remove terminated addons
      companyAddons.data.data.forEach((addon, index) => {
        if (addon.config.isTerminated === "1") {
          // remove addon from companyAddons.data.data array
          companyAddons.data.data.splice(index, 1);
        }
      });

      this.companyAddons = [];
      companyAddons.data.data.forEach((addon) => {
        // check, if addon is not terminated
        this.companyAddons.push(addon.id);
      });

      this.title = companyData.data.data[0].name;
      this.addonsList = response.data.data;

      this.preloader = false;
    },
  },

  mixins: [apiRequest],
};
</script>
