<template>
  <v-dialog v-model="dialogState" max-width="500" persistent>
    <v-card>
      <v-toolbar dark :color="propColor" elevation="0">
        <v-toolbar-title>{{ headline }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('reject')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text class="py-4">
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="$emit('reject')">Abbrechen</v-btn>
        <v-btn :color="color" text @click="$emit('accept')">{{
          propButtonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",

  props: ["dialog", "headline", "message", "color", "buttonText"],

  data() {
    return {
      propColor: this.color,
      propButtonText: this.buttonText,
    };
  },

  mounted() {
    if (!this.color) {
      this.propColor = "red";
    }

    if (!this.buttonText) {
      this.propButtonText = "Ja, löschen";
    }
  },

  computed: {
    dialogState: {
      get() {
        return this.dialog;
      },
      set() {},
    },
  },
};
</script>
