<template>
<div id="page">

  <PageHeader :pageTitle="pageTitle" :pageDescription="pageDescription"></PageHeader>

</div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest"
import PageHeader from '@/components/ui/PageHeader.vue'

export default {

  name: 'ActivateAddonAnnualMarketing',

  components: {
    PageHeader,
  },

  data () {
    return {
      pageTitle: 'Wir sind dran',
      pageDescription: 'Dieser Bereich ist noch in der Entwicklung',
      preloader: false,
      data: []
    }
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {

    async getData() {

      /* this.preloader= true;
      let response = await this.getRequest('companies/'+this.$route.params.id); // await data from mixin
      this.preloader= false;
      this.title = response.data.data.name; */

    }

  },

  mixins: [apiRequest],

}
</script>