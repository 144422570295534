<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-form
      ref="form"
      class="white my-4 pa-8"
      v-model="isFormValid"
      v-on:submit.prevent="showOverview()"
    >
      <v-row class="my-2">
        <v-col cols="12" sm="12" md="12">
          <v-select
            v-model="templates_id"
            :items="templateOptions"
            item-text="title"
            item-value="id"
            label="Template"
            hint="Das Template bildet die Grundlage"
            persistent-hint
            :rules="[rules.required]"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="Testzeitraum"
            v-model="trialPeriod"
            type="number"
            suffix="Tage"
            hint="Kündigungen innerhalb des Testzeitraums beenden den Vertrag zum Ende des Testzeitraums."
            persistent-hint
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="Kündigungsfrist"
            v-model="noticePeriod"
            type="number"
            suffix="Wochen"
            :hint="
              'Kündbar bis ' +
                this.noticePeriod +
                ' Wochen vor Ende der Vertragslaufzeit.'
            "
            persistent-hint
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-select
            v-model="runTime"
            :items="runTimeOptions"
            item-text="name"
            item-value="value"
            label="Vertragslaufzeit"
            :rules="[rules.required]"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Startdatum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hint="Das Plan-Jahr startet ab diesem Datum. Achte darauf das Datum möglichst nicht ans Ende eines Quartals zu legen."
                persistent-hint
                :rules="[rules.required]"
              ></v-text-field>
            </template>

            <v-date-picker v-model="startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="menu = false">Cancel</v-btn>
              <v-btn
                text
                color="green"
                @click="$refs.menu.save(startDate)"
                :loading="fetchinData"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" class="mt-6">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn
                v-if="isFormValid"
                large
                color="green"
                dark
                @click="confDialog = true"
                :loading="fetchinData"
                >Weiter</v-btn
              >
              <v-btn v-else large disabled>Weiter</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmationDialog
      :dialog="confDialog"
      color="green"
      buttonText="Plan-Zyklus starten"
      headline="Soll der Plan-Zyklus gestartet werden?"
      message="Bestätige bitte, dass der Plan-Zyklus mit den eingestellten Parametern gestartet werden soll. Der Plan ist sofort für den Kunden erreichbar."
      @reject="confDialog = false"
      @accept="activateAddon"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
export default {
  name: "Activate",

  components: {
    PageHeader,
    ConfirmationDialog,
  },

  data() {
    return {
      fetchinData: false,
      confDialog: false,
      isFormValid: false,
      pageTitle: "Jahresmarketing",
      pageDescription: "Addon-Aktivierung Jahresmarketing",
      templateOptions: [],
      title: "",
      preloader: false,
      templates_id: null,
      startDate: null,
      runTime: null,
      trialPeriod: "0",
      noticePeriod: "12",
      menu: false,
      modal: false,
      runTimeOptions: [
        {
          name: "12 Monate",
          value: "12",
        },
        {
          name: "24 Monate",
          value: "24",
        },
      ],
      rules: {
        required: (value) => !!value || "Pflichtfeld",
      },
    };
  },

  created: function() {
    this.getData(); // call it immediatly
  },

  methods: {
    async activateAddon() {
      /* companies_id:3
      templates_id:7
      startDate:2021-11-20
      runTime:12
      trialPeriod:0
      noticePeriod:8 */

      var formData = new FormData();
      formData.append("companies_id", this.$route.params.id);
      formData.append("templates_id", this.templates_id);
      formData.append("startDate", this.startDate);
      formData.append("runTime", this.runTime);
      formData.append("trialPeriod", this.trialPeriod);
      formData.append("noticePeriod", this.noticePeriod);

      this.preloader = true;
      let response = await this.postRequest(
        "/addons/annualMarketing/config",
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$emit("addonActivated", 1);
      }
    },

    async getData() {
      /* fetch templates */
      let response = await this.getRequest("planTemplates");
      if (response.status === 200) {
        this.templateOptions = response.data.data;
      }

      /* this.preloader= true;
      let response = await this.getRequest('companies/'+this.$route.params.id); // await data from mixin
      this.preloader= false;
      this.title = response.data.data.name; */
    },
  },

  mixins: [apiRequest],
};
</script>
