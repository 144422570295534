<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-btn large color="primary" @click="activateAddon()"> Aktivieren </v-btn>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
export default {
  name: "ActivateAddonEventCalendar",

  components: {
    PageHeader,
  },

  data() {
    return {
      fetchinData: false,
      confDialog: false,
      isFormValid: false,
      pageTitle: "Event-Kalender",
      pageDescription: "Addon-Aktivierung Event-Kalender",
      title: "",
      preloader: false,
    };
  },

  methods: {
    async activateAddon() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieses Addon tatsächlich aktiviert werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        var formData = new FormData();
        formData.append("companies_id", this.$route.params.id);
        // todo: later
        /* formData.append("startDate", this.startDate);
        formData.append("runTime", this.runTime);
        formData.append("trialPeriod", this.trialPeriod);
        formData.append("noticePeriod", this.noticePeriod); */

        this.preloader = true;
        let response = await this.postRequest(
          "/addons/eventCalendar/config",
          "",
          formData
        );
        this.preloader = false;

        if (response.status === 200) {
          this.$emit("addonActivated", 1);
        }
      }
    },
  },

  mixins: [apiRequest],
};
</script>
