<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          :items="data"
          :itemActions="{
            edit: true,
          }"
          :headers="headers"
          :preloader="preloader"
          :disableSearch="true"
          :disableItemsPerPage="true"
          :disablePagination="true"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="planDialog"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title
            >Plan #{{ planId }} — {{ companyName }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="planDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Plan :planId="planId" :companyId="companyId" :key="componentKey" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import DataTable from "@/components/ui/DataTable.vue";
import Plan from "@/components/companies/addons/annual_marketing/Plan.vue";
export default {
  name: "PlanOverview",

  components: {
    DataTable,
    Plan,
  },

  props: ["companyId"],

  data() {
    return {
      componentKey: 0,
      planDialog: false,
      preloader: false,
      data: [],
      headers: [],
      planId: null,
      companyName: null,
    };
  },

  created: async function () {
    this.getData(); // call it immediatly
    this.companyName = await this.getCompanyNameById(this.companyId);
  },

  methods: {
    async getData() {
      this.preloader = true;

      let response = await this.getRequest(
        "companies/" + this.companyId + "/addons/annualMarketing/plans"
      );

      this.preloader = false;
      this.data = response.data.data;

      this.headers = [
        { text: "ID", value: "id", sortable: false },
        { text: "Saison", value: "startYear", sortable: false },
        { text: "Letztes Update", value: "updated", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },

    async emittedAction(value) {
      if (value.action === "editItem") {
        this.componentKey += 1;
        this.planId = value.itemId;
        this.planDialog = true;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
