<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-btn large color="red" dark @click="deleteAddon">
      <v-icon small left color="white"> mdi-alert </v-icon>
      Vertrag kündigen
    </v-btn>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
export default {
  name: "EditAddonMitfitMember",

  components: {
    PageHeader,
  },

  props: ["companyId"],

  data() {
    return {
      pageTitle: "mitfitMember",
      pageDescription: "Addon-Verwaltung mitfitMember",
      preloader: false,
      usergroup: this.$store.getters["auth/user"].usergroup,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async deleteAddon() {
      const userChoice = await this.$dialog.warning({
        text: `Soll dieses Addon wirklich gekündigt werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        this.fetchingData = true;
        let response = await this.deleteRequest(
          `addons/mitfitMember/config/${this.companyId}`
        );
        this.fetchingData = false;
        if (response.status === 200) {
          this.$emit("terminated");
        }
        this.$emit("terminated");
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        "addons/annualMarketing/config/" + this.$route.params.id
      );
      this.data = response.data.data;
      this.preloader = false;
    },

    emittedAction(value) {
      this.data[value.name] = value.value;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
