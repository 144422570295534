<template>
  <v-container v-if="!preloader">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-chip
      v-if="data.inTermination === '1'"
      class="ma-2"
      color="red"
      text-color="white"
    >
      In Kündigung
    </v-chip>

    <v-simple-table>
      <template v-slot:default>
        <thead></thead>
        <tbody>
          <tr v-if="data.inTrial">
            <td class="font-weight-bold">Testzeitraum</td>
            <td>
              {{ data.trialPeriod }} Tage, endet am {{ data.trialEndDate }}
            </td>
          </tr>

          <tr v-else>
            <td class="font-weight-bold">Status</td>
            <td>Aktiv</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Kündigungsfrist</td>
            <td>{{ data.noticePeriod }} Wochen</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Verwendetes Template</td>
            <td>
              <Patcher
                :disabled="data.inTermination"
                :itemValue="data.templates_id"
                itemName="templates_id"
                :route="patchRoute"
                inputElement="select"
                :selectOptions="templateOptions"
                inputType="text"
                :previewValue="idToTitle(data.templates_id, templateOptions)"
                selectValueKey="id"
                selectTextKey="title"
                :hideLabel="true"
                @emit="data.templates_id = $event.value"
              ></Patcher>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Laufzeit</td>
            <td>{{ data.runTime }} Monate</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Beginn des Vertrags</td>
            <td>{{ niceStartDate }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Gebuchte Pausenmonate</td>
            <td v-if="data.paused.total">{{ data.paused.total }}</td>
            <td v-else>0</td>
          </tr>

          <template v-if="data.inTermination === '1'">
            <tr>
              <td class="font-weight-bold">Kündigung</td>
              <td>Vertrag wurde zum {{ data.terminationDate }} gekündigt</td>
            </tr>
          </template>

          <template v-else>
            <tr>
              <td class="font-weight-bold">Kündigung</td>
              <td class="py-2">
                <TerminateContract
                  :date="data.runTimeEndDate"
                  :companyId="companyId"
                  :data="data"
                  addonName="annualMarketing"
                  :key="componentKey"
                  @terminated="getData()"
                />
                <br />
                <em>Kündigungsfrist endet am {{ calculateNoticePeriod() }}</em>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <v-card class="my-2">
      <v-card-title>
        <span class="text-h6 font-weight-light">Pläne</span>
      </v-card-title>

      <v-card-text>
        <PlanOverview :companyId="companyId" />
      </v-card-text>

      <v-card-actions></v-card-actions>
    </v-card>

    <AddonBreaks
      :companyId="$route.params.id"
      addonName="annualMarketing"
      :configId="data.id"
      @newBreak="getData()"
    />

    <Notes
      :route="`/notes/annualMarketing/${companyId}`"
      :key="notesKey"
      class="my-4"
    />

    <v-card class="red darken-3 mt-5 mb-2 white--text">
      <v-card-title>
        <span class="text-h6 font-weight-light">DANGER-ZONE</span>
      </v-card-title>

      <v-card-text>
        <v-btn
          small
          color="red"
          @click="resetPlan()"
          :disabled="data.inTermination === '1' ? true : false"
          class="mx-1 white--text"
        >
          <v-icon small left color="white"> mdi-alert </v-icon>
          Aktiven Plan resetten
        </v-btn>

        <v-btn
          v-if="this.usergroup === 'developers'"
          small
          color="red"
          @click="deleteConfig()"
          class="mx-1 white--text"
        >
          <v-icon small left color="white"> mdi-skull </v-icon>

          Config &amp; Plan löschen
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>

  <v-container v-else>
    <v-skeleton-loader
      type="article: heading, paragraph,paragraph,paragraph,table: table-heading, table-thead, table-tbody, table-tfoot, card: table, card, card"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher.vue";
import PlanOverview from "@/components/companies/addons/annual_marketing/PlanOverview.vue";
import TerminateContract from "@/components/companies/addons/annual_marketing/TerminateContract.vue";
import AddonBreaks from "@/components/companies/addons/AddonBreaks.vue";
import Notes from "@/components/global/notes/Notes.vue";
export default {
  name: "EditAddonAnnualMarketing",

  components: {
    PageHeader,
    Patcher,
    PlanOverview,
    TerminateContract,
    AddonBreaks,
    Notes,
  },

  props: ["companyId"],

  data() {
    return {
      pageTitle: "Jahresmarketing",
      pageDescription: "Addon-Verwaltung Jahresmarketing",
      patchRoute: "addons/annualMarketing/config/" + this.$route.params.id, // PATCH addons/{addonName}/config/{companyId}
      title: "",
      preloader: false,
      data: [],
      templateOptions: [],
      plansNumber: 0,
      placeholder: "folgt",
      componentKey: 0,
      notesKey: 0,
      usergroup: this.$store.getters["auth/user"].usergroup,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  computed: {
    niceEndDate: function () {
      return this.formatDate(this.data.endDate);
    },
    niceStartDate: function () {
      return this.formatDate(this.data.startDate);
    },
    selectedTemplate: function () {
      let templName = "";

      this.templateOptions.forEach((template) => {
        if (template.id === this.data.templates_id) {
          templName = template.title;
        }
      });

      return templName;
    },
  },

  methods: {
    calculateNoticePeriod() {
      const startDate = new Date(this.data.runTimeEndDate);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const subtractedDate = new Date(
        startDate.getTime() - this.data.noticePeriod * millisecondsPerWeek
      );

      // Calculate the time difference in milliseconds
      const now = new Date(); // Get the current date/time
      const timeDiff = subtractedDate.getTime() - now.getTime();
      const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000)); // Convert milliseconds to days and round down

      return this.formatDate(subtractedDate) + ` (noch ${daysDiff} Tage)`;
    },

    async getPlans() {
      /* 
      Pläne des Studios einlesen.
      einzig und allein dafür, um steuern zu können, dass der Löschen Button nicht angezeigt wird, wenn es mehr als 1 Plan gibt */
      let response = await this.getRequest(
        "companies/" + this.companyId + "/addons/annualMarketing/plans"
      );
      this.preloader = false;
      this.plansNumber = response.data.data.length;
    },

    async getTemplates() {
      let response = await this.getRequest("planTemplates");
      if (response.status === 200) {
        this.templateOptions = response.data.data;
      }

      this.getPlans();
    },

    async getData() {
      // addons/{addonName}/config/{companyId}

      this.preloader = true;
      let response = await this.getRequest(
        "addons/annualMarketing/config/" + this.$route.params.id
      ); // await data from mixin

      this.data = response.data.data;

      this.getTemplates();
    },

    async resetPlan() {
      const userChoice = await this.$dialog.confirm({
        text: `<strong>IN ENTWICKLUNG! NOCH NICHT EINSETZBAR!</strong>. Soll der Plan von Company #${this.$route.params.id} wirklich resettet werden? Alle vom Kunden gemachten Änderungen gehen verloren. Diese Funktion ist allein dafür gedacht, eine "Planerstellung mit falschen Template" zu korrigieren. Denke also daran das Template vorher zu verändern.`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        alert("Diese Funktion befindet sich noch in der Entwicklung!");
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang wurde abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async deleteConfig() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll diese Config inkl. Plan von Company #${this.$route.params.id} wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.fetchingData = true;
        let response = await this.deleteRequest(
          `addons/annualMarketing/config/${this.$route.params.id}`
        );
        this.fetchingData = false;

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Conig und Plan wurden erfolgreich gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          location.reload();
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    emittedAction(value) {
      this.data[value.name] = value.value;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
